<template>
  <div class="todo-com-container" v-loading="loading">
    <div class="todo-com-header">
      <div class="todo-tabs">
        <div class="item" @click.stop="activeTab = 'tab1'">
          <span class="text" :class="activeTab === 'tab1' ? 'active' : ''"
            >待办事项</span
          >
          <span class="num-label">{{ todoListTotal }}</span>
        </div>
        <div class="item" @click.stop="activeTab = 'tab2'">
          <span class="text" :class="activeTab === 'tab2' ? 'active' : ''"
            >预警信息</span
          >
          <span class="num-label">{{ warringTotal }}</span>
        </div>
      </div>
      <div class="more">
        <i class="iconfont iconxiangyoujiantou2"></i>
      </div>
    </div>
    <section class="todo-main-warp">
      <TodoList
        v-show="activeTab === 'tab1'"
        :list="todoList"
        :loading="todoLoading"
      />
      <WarringList
        v-show="activeTab === 'tab2'"
        :list="warringList"
        v-loading="warringLoading"
        @refresh="getWarringList(true)"
        @send-scroll-event="handleScrollEvent"
      />
    </section>
  </div>
</template>

<script>
import TodoList from "./TodoList.vue";
import WarringList from "./WarringList.vue";
import { getNewHomeList } from "@/apis/home";
import { dataInterface } from "@/apis/data";
import { mapState } from "vuex";
import eventBus from "@/plugins/eventBus";
export default {
  components: { TodoList, WarringList },
  props: {
    // archiID: {
    //   type: [String, Number],
    //   default: ''
    // }
  },
  data() {
    return {
      activeTab: "tab1",
      todoList: [],
      todoListTotal: 0,
      warringList: [],
      warringPage: 0,
      warringTotal: 0,
      todoLoading: false,
      warringLoading: false,
      warringListIsEnd: false,
      loading: false,
      isUpdate: true, // 是否到底
      page: 1,
      size: 20,
      total: 0,
      timer: ''
    };
  },
  computed: {
    ...mapState(["targetArchi"]),
  },
  watch: {
    targetArchi: {
      handler() {
        this.getTodoList();
        this.getWarringList(true);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getTodoList();
    this.getWarringList(true);
  },
  mounted() {
    /**
     * @desc: 操作完毕关闭弹窗
     */
    eventBus.$on("updateTodoList", () => {
      this.getTodoList();
    });
  },
  methods: {
    getTodoList() {
      this.loading = true;
      const data = {
        msg_type: 1,
      };
      if (this.targetArchi) {
        data.archi_id = this.targetArchi.id;
      }
      getNewHomeList(data)
        .then((res) => {
          this.loading = false;
          if (res.data.code === 200) {
            this.todoListTotal = res.data.data.length;
            this.todoList = res.data.data;
          }
        })
        .catch(() => (this.loading = false));
    },
    getWarringList(bool) {
      this.warringLoading = true;
      const reqData = {
        __method_name__: "dataList",
        transcode: 0,
        object_uuid: "object635201835e212",
        view_uuid: "view63a85fe0ba34c",
          size:this.size,
          page:this.page
      };
      if (this.targetArchi) {
        reqData.archi_id = this.targetArchi.id;
      }
      dataInterface(reqData)
        .then((res) => {
          this.warringLoading = false;
          if (res.data.code === 200) {
            /* bool值决定了是否重新刷新数据 */
            if (bool) {
              this.warringList = []
              this.page = 1
            }
            let list = res.data.data.data || [];
            let warringList = this.warringList;
            if (Array.isArray(list) && list.length) {
              list.forEach(item => {
                warringList.push(item)
              })
              this.warringList = warringList;
              this.warringTotal = res.data.data.total;
            }else{
              if(list.length === 0 && this.page > 1){
                this.isUpdate = false
                this.$message.warning("没有更多数据了");
              }
            }
          }
        })
        .catch(() => (this.warringLoading = false));
    },
    /**
     * @desc: 滚动加载
     * @param {Object} e
     */
     handleScrollEvent(e) {
      if (
        e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight > -10
        ) {
          if (this.isUpdate) {
            this.page += 1;
            this.getWarringList();
          } else {
            if(this.timer) {
              return 
            }
            this.timer = setTimeout(() => {
              this.$message.warning("没有更多数据了");
              this.timer = null
            }, 1000)
          }
        }
    },
  },
};
</script>
<style lang="less" scoped>
.todo-com-container {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: #fff;
  padding: 12px 10px;
  box-sizing: border-box;
  .todo-com-header {
    height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    box-sizing: border-box;
    .todo-tabs {
      height: 100%;
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        cursor: pointer;
        .text {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #6d768f;
          line-height: 24px;
          letter-spacing: 1px;
          &:hover {
            color: #409eff;
          }
        }
        .active {
          color: #2a2f3d;
        }
        .num-label {
          margin-left: 8px;
          height: 18px;
          line-height: 18px;
          display: inline-block;
          padding: 0 8px;
          border-radius: 10px;
          background: #33bbff;
          color: #fff;
        }
        & + .item {
          margin-left: 24px;
        }
      }
    }
    .more {
      &:hover {
        color: #409eff;
      }
    }
  }
  .todo-main-warp {
    height: calc(100% - 32px);
    .todo-list-warp {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .list-item {
        width: 100%;
        display: flex;
        padding: 12px 0px;
        justify-content: space-between;
        align-items: flex-end;
        .info-warp {
          display: flex;
          .info-icon {
            height: 36px;
            width: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 6px;
            font-size: 22px;
            font-family: YouSheBiaoTiHei;
          }
          .q-icon {
            background: rgba(90, 204, 120, 0.2);
            color: #5acc78;
          }
          .s-icon {
            background: rgba(255, 170, 74, 0.2);
            color: #ffaa4a;
          }
          .p-icon {
            background: rgba(51, 187, 255, 0.2);
            color: #33bbff;
          }
          .info-text-warp {
            margin-left: 12px;
            .line-1 {
              display: flex;
              align-items: center;
              .info-title {
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 500;
                color: #292f3d;
                line-height: 22px;
              }
              .status {
                margin-left: 8px;
                height: 20px;
                padding: 0 8px;
                box-sizing: border-box;
                border-radius: 12px;
                line-height: 20px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
              }
              .w-status {
                background: #fff1f1;
                color: #e0452d;
              }
              .s-status {
                background: #e1f5e7;
                color: #21a35d;
              }
              .p-status {
                color: #0088cc;
                background: #e0f4ff;
              }
            }
            .line-2 {
              height: 18px;
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #6d768f;
              line-height: 18px;
            }
          }
        }
        .time-status {
          height: 100%;
          white-space: nowrap;
          height: 18px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6d788f;
          line-height: 18px;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
