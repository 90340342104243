<!--
 * @Author: zqy
 * @Date: 2022-06-17 18:32:04
 * @LastEditTime: 2023-12-11 14:56:15
 * @Description: 
 * @LastEditors: 朱廷果 1028509503@qq.com
-->
<template>
  <div class="notice" v-loading="loading">
    <header>
      <div class="left">
        <div
          @click="readStatus = 'unread'"
          style="display: flex; align-items: center"
        >
          <h4
            class="title"
            style="color: #2A2F3D;"
          >
            通知公告
          </h4>
          <div v-show="unreadAllList.length" class="number">
            <p>{{ unreadAllList.length }}</p>
          </div>
        </div>
        <!-- <h4
          @click="readStatus = 'read'"
          class="title"
          style="margin-left: 24px"
          :style="readStatus === 'read' ? 'color: #2A2F3D;' : 'color: #6D768F;'"
        >
          已读
        </h4> -->
      </div>
      <div class="right">
        <i class="iconfont iconxiangyoujiantou2" @click="goNotice"></i>
      </div>
    </header>
    <ul class="notice-list"  @scroll="scrollEvent">
      <template v-if="showNoticeList && showNoticeList.length">
        <li
          v-for="(item, index) in showNoticeList"
          :key="index"
          class="list-item"
          @click="openDetail(item)"
        >
          <div class="left">
            <el-badge is-dot class="item" v-if="+item.is_read !== 1">
              <div class="badge" :class="getClass(item)">
                {{ item.notice_type }}
              </div>
            </el-badge>
            <div class="badge" v-else :class="getClass(item)">
              {{ item.notice_type }}
            </div>
            <p
              class="title"
              :style="
                +item.is_read === 1 ? 'color:#6D768F' : 'color:#2A2F3D'
              "
            >
              {{ item.title }}
            </p>
          </div>
          <div
            class="right"
            :style="readStatus === 'unread' ? 'color:#A9ADB8' : 'color:#6D768F'"
          >
            {{ getFormatDate(item.created_at) }}
          </div>
        </li>
      </template>
      <el-empty v-else description="暂无数据"></el-empty>
    </ul>
    <!-- <DetailDailog
      v-if="detailShow"
      form_uuid="form629084dae1b4a"
      :data_id="data_id"
      title="详情"
    ></DetailDailog> -->
  </div>
</template>
<script>
import { formRequest } from "@/apis/data/form";
import { Badge, Empty } from "element-ui";
import { mapState } from "vuex";
// import DetailDailog from '@/components/global/Dailog'
const BLUETYPES = ["公告", "公报", "决议", "决定"];
// const PURPLETYPES = ['通知','通告','通报','意见','命令']
export default {
  components: {
    "el-badge": Badge,
    "el-empty": Empty,
    // DetailDailog
  },
  props: {
    archi_id: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      noticeList: [],
      data_id: "",
      detailShow: false,
      dailogTitle: "",
      readStatus: "unread", //未读  read
      unreadList: [],
      unreadAllList: 0,
      readList: [],
      loading: false,
      // 分页数据
      page: 1,
      size: 10,
      total: 0,
      isUpdate: true, // 是否到底
    };
  },
  computed: {
    showNoticeList() {
      // if (this.readStatus === "unread") {
      //   return this.unreadList;
      // } else {
        return this.readList;
      // }
    },
    ...mapState(["targetArchi"]),
  },
  watch: {
    targetArchi: {
      handler() {
        this.getNoticeList();
        this.getNoticeLength();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getReadStatus(user) {
      const userId = this.$GetUserInfo("id");
      if (user && Array.isArray(user) && user.length) {
        let flag = false;
        for (let i = 0; i < user.length; i++) {
          if (+user[i].yong_id === +userId && user[i].is_read) {
            //已读
            flag = true;
          }
        }
        if (flag) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    openDetail(item) {
      // this.dailogTitle = `${item.notice_type}详情`
      // this.data_id = item.id
      // this.detailShow = true
      let data = {
        event: "showDetailModal",
        form_uuid: "form629084dae1b4a",
        data_id: item.id,
        object_name: "详情",
      };
      window.parent.postMessage(data, "*");
      // 标记已读
      // if (item.is_read == 0) {
      //   this.getNoticeList();
      // }
      // return;

      // !!! 以下不需要了，从详情占位事件中走了
      const url = "api/mapi",
        method = "POST";
      formRequest(method, url, {
        data_id: item.id,
        object_uuid: "object62907b0f7a148",
        view_uuid: "view62ac53fbb5b90",
        __method_name__: "dataInfo",
        transcode: 0,
      }).then((res) => {
        if (res && +res.data.code == 200) {
          const data = res.data.data;
          const url = "api/mapi",
            method = "POST";
          if (data && data.id) {
            formRequest(method, url, {
              notice_id: data.id,
              object_uuid: "object62a938469ffdd",
              view_uuid: "view62b691771eb7c",
              __method_name__: "dataList",
              transcode: 0,
              page: 1,
              size: 1000,
            }).then((res) => {
              if (res && +res.data.code == 200) {
                const userList = res.data.data.data;
                const userId = this.$GetUserInfo("id");
                const obj = userList.filter(
                  (item) => +item.yong_id === +userId && !item.is_read
                );
                if (obj && obj.length) {
                  formRequest(method, url, {
                    data_id: obj[0].id,
                    is_read: 1,
                    object_uuid: "object62a938469ffdd",
                    __method_name__: "updateData",
                  }).then((res) => {
                    if (res && +res.data.code == 200) {
                      this.readList = [];
                      this.unreadList = [];
                      this.page = 1
                      this.getNoticeList();
                      this.getNoticeLength();
                    }
                  });
                }
              }
            });
          }
        }
      });
      return;
    },
    getClass(item) {
      if (BLUETYPES.indexOf(item.notice_type) !== -1) {
        return "blue-card";
      } else {
        return "purple-card";
      }
    },
    getFormatDate(created_at) {
      const date = new Date(created_at);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString();
      const day = date.getDate().toString();
      if (created_at) {
        return `${year}/${month}/${day}`;
      } else {
        return "-";
      }
    },
    getNoticeList() {
      this.loading = true;
      const url = "api/mapi",
        method = "POST";
      const param = {
        object_uuid: "object62907b0f7a148",
        view_uuid: "view62ac53fbb5b90",
        __method_name__: "dataList",
        transcode: 0,
        size: this.size,
        page: this.page,
      };
      // if (this.archi_id) {
      //   param.archi_id = this.archi_id
      // }
      formRequest(method, url, param)
        .then((res) => {
          this.loading = false;
          if (res && +res.data.code == 200) {
            let data = res.data.data.data || [];
            this.noticeList = data;
            let unreadList = this.unreadList;
            
            if (data && data.length) {
              data.forEach((item) => {
                let flag = +item.is_read == 1 ? true : false;
                if (!flag) {
                  unreadList.push(item);
                }
              });
              this.unreadList = unreadList;
            } else {
              this.page -= 1;
              if (data.length === 0) {
                this.isUpdate = false;
              }
            }
          }
        })
        .catch(() => (this.loading = false));
    },
    /**
     * @desc: 获取通知总数
     * @return {*}
     */
    getNoticeLength() {
      const url = "api/mapi",
        method = "POST";
      const param = {
        object_uuid: "object62907b0f7a148",
        view_uuid: "view62ac53fbb5b90",
        __method_name__: "dataList",
        transcode: 0,
        size: 1000,
        page: 1,
      };
      // if (this.archi_id) {
      //   param.archi_id = this.archi_id
      // }
      formRequest(method, url, param).then((res) => {
        if (res && +res.data.code == 200) {
          let data = res.data.data.data || [];
          this.unreadAllList = 0;
          this.readList =data;
          let arr = [];
          if (data && data.length) {
            data.forEach((item) => {
              let flag = +item.is_read == 1 ? true : false;
              if (flag) {
                  // this.readList.push(item);
                } else {
                  arr.push(item);
                }
            });
          }
          this.unreadAllList = arr;
        }
      });
    },

    goNotice() {
      // this.$store.commit('setShowAppCenter', true);
      this.$router.push({
        path: "notice-notice",
        query: {
          pageUUID: "page62908b64de319",
        },
      });
    },
    /**
     * @desc: 滚动加载
     * @param {Object} e
     */
    scrollEvent(e) {
      if (
        e.target.offsetHeight + e.target.scrollTop - e.target.scrollHeight === 0
      ) {
        if (this.isUpdate) {
          this.page += 1;
          this.getNoticeList();
        } else {
          this.$message.warning("没有更多数据了");
        }
      }
    },
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.blue-card {
  background: #ddf1fb;
  color: #31b4f5;
}
.purple-card {
  background: #f0eaff;
  color: #9c86ff;
}
.notice {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  padding: 16px 20px;
  box-sizing: border-box;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  header {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: space-between;
    padding: 4px 0;
    .left {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6d768f;
      display: flex;
      align-items: center;
      .number {
        margin-left: 8px;
        height: 18px;
        line-height: 18px;
        display: inline-block;
        padding: 0 8px;
        border-radius: 10px;
        background: #33bbff;
        color: #fff;
        font-size: 14px;
      }
    }
    .right {
      i {
        font-size: 20px;
      }
    }
  }
  .notice-list {
    width: 100%;
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    .list-item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 12px 0;
      overflow: hidden;
      .left {
        display: flex;
        width: calc(100% - 70px);
        align-items: center;
        .badge {
          width: 34px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          // background: rgb(229,240,250);
          border-radius: 2px;
          font-size: 12px;
          // color: #099FEE;
          font-family: YouSheBiaoTiHei;
          white-space: nowrap;
          flex-shrink: 0;
        }
        .title {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2a2f3d;
          padding-left: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .right {
        font-size: 12px;
        line-height: 21px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6d768f;
        padding-right: 8px;
      }
    }
  }
}
</style>
