<!--
 * @Description: v
 * @Author: luocheng
 * @Date: 2022-06-17 19:20:39
 * @LastEditors: luocheng
 * @LastEditTime: 2022-08-15 14:02:06
-->
<template>
  <div class="second-main">
    <CommonGridContainer></CommonGridContainer>
  </div>
</template>

<script>
import { getTargetArchi } from '@/utils/tools.js'
import CommonGridContainer from './GridComponent'

export default {
  name: 'SecondMain',
  components: {
    CommonGridContainer,
  },
  data() {
    return {
      archiID: ''
    }
  },
  created() {
    this.archiID = getTargetArchi() ? getTargetArchi().data_id : ''
  }
}
</script>

<style lang="less" scoped>
.second-main{
  height: 100%;
  width: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 0px 44px;
  .left{
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    .part{
      width: 50%;
      box-sizing: border-box;
      padding: 0 8px 8px 0;
      height: 50%;
      border-radius: 8px;
      overflow: hidden;
      &:nth-of-type(2n) {
        padding-right: 0;
      }
      &:nth-of-type(3),&:nth-of-type(4) {
        padding-bottom: 0;
      }
      .space{
        height: 100%;
        width: 100%;
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
      }
    }
  }
  .right{
    flex-shrink: 0;
    width: 29%;
    height: 100%;
    min-width: 300px;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
}
</style>