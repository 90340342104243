<template>
    <div class="todo-list-warp" v-loading="loading">
        <div class="list-item" v-for="(item, index) in list" :key="index" @click.stop="handleClick(item)">
            <div class="info-warp">
                <div class="info-icon" :class="filterIcon(item.object_name).class">
                    <span>{{filterIcon(item.object_name).text}}</span>
                </div>
                <div class="info-text-warp">
                    <div class="line-1">
                        <p class="info-title">{{ item.object_name }} | {{ item.title }}</p>
                        <p class="status" :class="filterStatus(item.status).class">{{ filterStatus(item.status).label }}</p>
                    </div>
                    <div class="line-2">
                        <p class="project-name">项目：{{ item.archi_name }}</p>
                        <p class="time-status">{{ filterTime(item.endDate).time }}</p>
                    </div>
                </div>
            </div>
        </div>
        <Empty v-if="list.length === 0" description="暂无数据"  :image-size="100"></Empty>
    </div>
</template>
<script>
import { format } from "date-fns";
import { Empty } from 'element-ui';
export default {
    components: {
        Empty
    },
    props: {
        list: {
            type: Array,
            default () {
                return []
            },
            require: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    }, 
    data() {
        return {

        }
    },
    methods: {
        handleClick (item) {
          console.log(item)
            let data = {
                event: "showDetailModal",
                form_uuid: item.form_id,
                flow_id: item.flow_id,
                data_id: item.id,
                module_name: item.module_name,
                object_name:item.object_name,
            };
            window.parent.postMessage(data, "*");
            return;
        },
        filterIcon(text) {
            if(!text || text.length === 0) {
                return {
                text: '-',
                class: ''
                }
            }
            const str = text[0]
            const classHash = {
                '安': 's-icon',
                '质': 'q-icon',
                '进': 'p-icon',
            }
            return {
                text: str,
                class: classHash[str] || classHash[Object.keys(classHash)[Math.floor(Math.random() * Object.keys(classHash).length)]]
            }
        },
        filterStatus(status) {
            switch(status + '') {
                case '-1' : {
                    return {
                        label: '退回',
                        class: 'w-status'
                    }
                }
                case '0' : {
                    return {
                        label: '待初审',
                        class: 'd-status'
                    }
                }
                case '5' : {
                    return {
                        label: '反馈完成',
                        class: 's-status'
                    }
                }
                default: {
                    return {
                        label: '审批中',
                        class: 'p-status'
                    }
                }
            }
        },
        filterTime(time) {
            const targetDate = new Date(time)
            if(isNaN(targetDate.getTime())) {
                return {
                    time: '-',
                    className: '',
                    str: '',
                }
            } else {
                return this.timeFormat(time)
            }
        },
        // 时间格式化
        timeFormat (time) {
            if (!this.nowDateObj) {
                this.nowDateObj = new Date();
            }
            const targetDate = new Date(time);
            const diffTime = this.nowDateObj.getTime() - targetDate.getTime()
            if(diffTime > (24 * 3600 * 1000)) {
                return {
                    time: `逾期${Math.floor(diffTime / (24 * 3600 * 1000))} 天`,
                    class: 'w-status',
                    str: '已滞后',
                }
            }
            const ny = this.nowDateObj.getFullYear();
            const ty = targetDate.getFullYear();
            const nm = this.nowDateObj.getMonth();
            const tm = targetDate.getMonth();
            const nd = this.nowDateObj.getDate();
            const td = targetDate.getDate();
            if (ny === ty) {
                if (nm === tm) {
                if (nd === td) {
                    return {
                        time: `今天${format(targetDate, "HH:mm")}截止`,
                        class: 's-status',
                        str: '待处理',
                    };
                } else if (td - nd === 1) {
                    return {
                        time: `明天${format(targetDate, "HH:mm")}截止`,
                        class: 'p-status',
                        str: '待处理',
                    };
                } else {
                    return {
                        time: `${format(targetDate, "MM-dd HH:mm")}截止`,
                        class: 'p-status',
                        str: '待处理',
                    }
                }
                } else {
                return {
                        time: `${format(targetDate, "MM-dd HH:mm")}截止`,
                        class: 'p-status',
                        str: '待处理',
                    };
                }
            } else {
                return {
                    time: `${format(targetDate, "yyyy-MM-dd HH:mm:ss")}截止`,
                    class: 'p-status'
                };
            }
        },
    },

}
</script>

<style lang="less" scoped>
.todo-list-warp{
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .list-item{
        width: 100%;
        display: flex;
        padding: 12px 10px;
        box-sizing: border-box;
        align-items: flex-end;
        cursor: pointer;
        &:hover{
            background: #f5f5f5;
            border-radius: 3px;
        }
        .info-warp{
            flex-grow: 1;
            width: 100px;
            display: flex;
            .info-icon{
                flex-shrink: 0;
                height: 36px;
                width: 36px;
                line-height: 36px;
                text-align: center;
                border-radius: 6px;
                font-size: 22px;
                font-family: YouSheBiaoTiHei;
            }
            .q-icon{
                background: rgba(90, 204, 120, .2);
                color: #5ACC78 ;
            }
            .s-icon{
                background: rgba(255, 170, 74, .2);
                color: #FFAA4A;
            }
            .p-icon{
                background: rgba(51, 187, 255, .2);
                color: #33BBFF;
            }
            .other-icon{
                background: #f1f1f1;
            }
            .info-text-warp{
                margin-left: 12px;
                flex-grow: 1;
                width: 100px;
                .line-1{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                    .info-title{
                        flex-grow: 1;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #292F3D;
                        line-height: 22px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .status{
                        flex-shrink: 0;
                        margin-left: 8px;
                        height: 20px;
                        padding: 0 8px;
                        box-sizing: border-box;
                        border-radius: 12px;
                        line-height: 20px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        white-space: normal;
                    }
                    .w-status{
                        background: #FFF1F1;
                        color: #E0452D;
                    }
                    .s-status{
                        background: #E1F5E7;
                        color: #21A35D;
                    }
                    .p-status{
                        color: #0088CC;
                        background: #E0F4FF;
                    }
                    .d-status{
                        color: #007d79;
                        background: #E1F7F6;
                    }
                }
                .line-2{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 2px ;
                    height: 18px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #6D768F;
                    line-height: 18px;
                    .project-name{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        flex-grow: 1;
                        width: 10px;
                    }
                    .time-status{
                        flex-shrink: 0;
                        white-space: nowrap;
                        height: 18px;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #6D788F;
                        line-height: 18px;
                        margin-right: 5px;
                    }
                }
            }
        }
        // .time-status{
        //     flex-shrink: 0;
        //     white-space: nowrap;
        //     height: 18px;
        //     font-size: 12px;
        //     font-family: PingFangSC-Regular, PingFang SC;
        //     font-weight: 400;
        //     color: #6D788F;
        //     line-height: 18px;
        //     margin-right: 5px;
        // }
    }
}
</style>
