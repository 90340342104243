
<template>
  <div class="weather-conditions" v-if="weatherData">
    <section class="item">
      <i class="iconfont weather-icon" :class="weatherClass"></i>
      <div class="desc">
        <p class="value">{{ weatherData.tem || "--" }}°C</p>
        <p class="label">天气</p>
      </div>
    </section>
    <section class="item">
      <i class="iconfont weather-icon">
        <img src="@/assets/images/dataview/pm25.png" alt="" />
      </i>
      <div class="desc">
        <p class="value">{{ weatherData.pm25 || "--" }}</p>
        <p class="label">PM2.5</p>
      </div>
    </section>
  </div>
</template>

<script>
import { getWeather } from "@/apis/data";
/**
 * @description 天气
 */
export default {
  name: "WeatherConditions",
  data() {
    return {
      weatherData: null
    }
  },
  computed: {
    weatherClass() {
      if (!this.weatherData) return ["iconfont", "weather-icon"];
      let weatherImg = this.weatherData.wea_img;
      // 多云用阴天图标
      if (this.weatherData.wea_img === "yun") {
        weatherImg = "yin";
      }
      return [`icontianqi-${weatherImg}`, `color-${weatherImg}`];
    },
  },
  created() {
    this.getWeatherInfo();
  },
  methods: {
    // 获取天气情况
    getWeatherInfo() {
      getWeather().then(res => {
        if (res.data && res.data.city && res.data.data) {
          this.weatherData = Object.assign({}, res.data.data[0], res.data.aqi)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.weather-conditions {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;
  padding: 9px 16px;
  padding-right: 0;
  .item {
    // margin-top: 10px;
    height: 34px;
    display: flex;
    align-items: center;
    .weather-icon {
      height: 24px;
      width: 24px;
      font-size: 20px;
      text-align: center;
      line-height: 24px;
      margin-top: 5px;
      overflow: hidden;
      &.color-yin {
        color: #878c90;
      }
      &.color-qing {
        color: #ffb700;
      }
      &.color-yu {
        color: #00ccff;
      }
      &.color-yun {
        color: #878c90;
      }
      img {
        display: block;
        height: 20px;
        width: 20px;
        margin-top: 2px;
        overflow: hidden;
        object-fit: cover;
      }
    }
    .desc {
      margin-left: 6px;
      .value {
        min-width: 36px;
        max-width: 60px;
        line-height: 18px;
        height: 18px;
        opacity: 1;
        font-size: 15px;
        font-family: DINNextLTPro, DINNextLTPro-Medium;
        font-weight: 500;
        text-align: left;
        color: #fff;
      }
      .label {
        padding-top: 4px;
        width: 24px;
        height: 12px;
        opacity: 1;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        line-height: 12px;
        color: #fff;
      }
    }
  }
}
</style>
