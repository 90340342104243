<!--
 * @Description: 首页
 * @Author: luocheng
 * @Date: 2021-09-13 13:55:25
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2023-01-05 11:52:33
-->
<template>
  <div class="statistic-box">
    <h4 class="title">建设看板</h4>
    <div class="main">
      <div class="content" v-if="show">
        <div class="content-item-box" v-for="(item,index) in infoList" :key="index">
          <div class="content-item" :style="'background:' + item.background" @click="handleClick(item)">
            <div class="item-left">
              <p class="item-title">{{item.title}}</p>
              <div class="sub-title-box">
                <p class="sub-title" v-if="item.rate" :style="'color:' + item.color">{{item.rate}}</p>
                <p class="sub-title" v-if="item.rank" :style="'color:' + item.color">{{item.rank}}</p>
              </div>
            </div>
            <div class="item-right">
              <img :src="item.icon" style="display:block;" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="map-box" v-loading="dynamicLoading">
        <div class="todo-tabs">
          <div class="item" @click.stop="checkTab('tab1')">
            <span class="text" :class="activeTab === 'tab1' ? 'active' : ''">平台动态</span>
          </div>
          <div class="item" @click.stop="checkTab('tab2')">
            <span class="text" :class="activeTab === 'tab2' ? 'active' : ''">工程图册</span>
          </div>
        </div>
        <section class="todo-main-warp" >
          <div v-if="init && activeTab === 'tab1'" class="dynamic-box">
            <DynamicList class="dynamic-list" :list="dynamicList" :rankData="rankList" :isUpdate="isUpdate" @refresh="refresh" />
          </div>
          <div v-if="activeTab === 'tab2'" class="image-box">
            <el-image v-if="rightImg" fit="fill" :src="rightImg" class="img"></el-image>
            <el-empty v-else description="暂无数据" :image-size="80"></el-empty>
          </div>
        </section>
        
      </div>
    </div>
  </div>
</template>

<script>
import { infoList } from './config'
import { formRequest } from "@/apis/data/form";
import { Empty } from 'element-ui';
import DynamicList from './dynamicList.vue'
import { mapState } from 'vuex';
export default {
	name: 'Statistic',
	components: {
    'el-empty': Empty,
    DynamicList
  },
	data() {
		return {
      rightImg: '',
      show:false,
      infoList:infoList,
      activeTab: 'tab1',
      dynamicList:[],
      rankList:[],
      dynamicLoading:true,
      page:1,
      size:20,
      total:0,
      isUpdate:true, // 是否到底
      init:false,
      defaultAvatar:require('@/assets/images/default-avatar.png')
		};
	},
  computed: {
    ...mapState(['targetArchi'])
  },
  watch: {
    targetArchi: {
      handler() {
        this.getBuildBoardData()
        this.getDynamicList()
      },
      deep: true,
      immediate: true
    }
  },
	methods: {
    checkTab(tab){
      this.activeTab = tab
      if(tab === 'tab2'){
        this.getRight()
      }
    },
    getRight(){
      const url='api/mapi',method='POST';
      const imgParam = {
        object_uuid: 'object62c304b65b0cf',
        view_uuid: 'view62c3096c5d337',
        __method_name__: 'dataInfo',
        transcode: 0
      }
      // 获取图片路径
      formRequest(method,url,imgParam).then(re=>{
        if(re.data.code === 200){
          const data = re.data.data
          if(data.homepage_picture && data.homepage_picture.length){
            this.rightImg = data.homepage_picture[0].url
          }
        }
      })
    },
    refresh(){
      this.page  += 1
      this.updateData()
    },
     /**
     * @desc:下拉刷新数据
     */
    async updateData(){
      this.dynamicLoading = true
      const url='api/mapi',method='POST';
      const param = {
        object_uuid: 'object62cbe374ef233',
        view_uuid: 'view62cd1cf39e2f3',
        __method_name__: 'dataList',
        transcode: 0,
        size:this.size,
        page:this.page
      }
      let res = await formRequest(method,url,param)
      if(res.data.code === 200){
        let dataList = this.dynamicList
        let list = res.data.data.data
       
        if(list && list.length){
          list.forEach(item=>{
            if(!item.avatar){
              item.avatar = this.defaultAvatar
            }
            dataList.push(item)
          })
          this.dynamicList = dataList
          this.total = dataList.length
        }else{
          this.page -= 1
          if(list.length === 0){
            this.isUpdate = false
            this.$message.warning('没有更多数据了')
          }else{
            this.$message.error('没有更多数据了')
          }
        }
      }
      this.dynamicLoading = false
    },
    /**
     * @desc: 获取建设看板（利率和名次）
     */
    getBuildBoardData(){
      this.show = false
      const url='api/mapi',method='POST';
      const param = {
        object_uuid: 'object62b90a0c70a9e',
        view_uuid: 'view62b90ebe25920',
        __method_name__: 'dataList',
        transcode: 0
      }
      formRequest(method,url,param).then(res=>{
        if(res.data.code === 200){
          let data = res.data?.data?.data
          if(data && data.length ){
            const {investment_completion_rate = 0,progress_completion_rate = 0,safety_evaluation = 0,quality_evaluation = 0,safety_evaluation_rank = 0,quality_evaluation_rank = 0} = data[0]
            this.infoList[0].rate = investment_completion_rate ? investment_completion_rate + '%' : ''
            this.infoList[1].rate = progress_completion_rate ? progress_completion_rate + '%' : ''
            this.infoList[2].rate = safety_evaluation ? safety_evaluation + '分' : ''
            this.infoList[3].rate = quality_evaluation ? quality_evaluation + '分' : ''
            this.infoList[2].rank = safety_evaluation_rank ? 'NO.' + safety_evaluation_rank : ''
            this.infoList[3].rank = quality_evaluation_rank ?  'NO.' + quality_evaluation_rank : ''
          }

        }
        // console.log(this.infoList)
        this.show = true
      })
    },
    /**
     * @desc: 获取工程动态数据列表
     */
    async getDynamicList(){
      this.dynamicLoading = true
      const url='api/mapi',method='POST';
      const param = {
        object_uuid: 'object62cbe374ef233',
        view_uuid: 'view62cd1cf39e2f3',
        __method_name__: 'dataList',
        transcode: 0,
        size:this.size,
        page:this.page
      }
      let res = await formRequest(method,url,param)
      if(res.data.code === 200){
        this.dynamicList = res.data.data.data
        if(this.dynamicList?.length){
          this.dynamicList.forEach(item=>{
            if(!item.avatar){
              item.avatar = this.defaultAvatar
            }
          })
        }
        
        this.total = res.data.data.total
      }
      const rankParam = {
        object_uuid: 'object62cbe374ef233',
        view_uuid: 'view62ce9543b87f7',
        __method_name__: 'dataList',
        transcode: 0,
      }
      let rankRes = await formRequest(method,url,rankParam)
      if(rankRes.data.code === 200){
        let arr = rankRes.data.data
        if(arr.length){
          arr.sort((a,b)=>parseInt(b.nums) - parseInt(a.nums))
          arr.forEach(item=>{
            if(!item.avatar){
              item.avatar = this.defaultAvatar
            }
          })
        }
        if(arr.length > 5){
          this.rankList = arr.slice(0,5)
        }else{
          this.rankList = arr
        }
        this.init = true
      }
      this.dynamicLoading = false
    },
    /**
     * @desc: 计算页数
     * @param:total(总条数) pageSize(每页的数量)
     */
    // pageTotal(total,pageSize){
    //   if(total == null || pageSize == null){
    //     return 0;
    //   }
    //   if(pageSize != 0 && total % pageSize == 0){
    //     return parseInt(total / pageSize);
    //   }
    //   if(pageSize != 0 && total % pageSize != 0){
    //     return parseInt(total / pageSize) + 1;
    //   }
      
    // },
    /**
     * @desc: 点击快捷方式(建设看板)
     * @param {Object} item
     */
    handleClick(item){
      const {
        path = '',
        query = {},
        isScreen = false,
        // originData = {}
      } = item;
      if (!path) {
        this.$message.error('暂未配置目标页面!');
        return;
      }
      if (isScreen) {
        window.open(path.indexOf('http') === 0 ? path : window.origin + path);
        return;
      }
      this.$router.push({
        path,
        query
      })
    }
	}
};
</script>

<style lang="less" scoped>
  .statistic-box{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 16px 20px 20px 20px;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: auto;
    .title{
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #2A2F3D;
      letter-spacing: 1px;
    }
    .main{
      height: calc(100% - 24px);
      display: flex;
      flex-direction: column;
      .content{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .content-item-box{
          width: 48%;
          padding: 8px 0;
          .content-item{
            padding: 23px 20px 19px 20px;
            background: #fff;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .item-left{
              flex: 1;
              padding-right: 16px;
              box-sizing: border-box;
              overflow: hidden;
              .item-title{
                height: 22px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #2A2F3D;
                line-height: 22px;
              }
              .item-title{
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
              .sub-title-box{
                // width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                .sub-title{
                  height: 32px;
                  font-size: 24px;
                  font-family: YouSheBiaoTiHei;
                  color: #3881FF;
                  line-height: 32px;
                }
              }
            }
            .item-right{
              width: 48px;
              height: 48px;
              img{
                width: 48px;
                height: 48px;
              }
            }
          }
        }
        
      }
      .map-box{
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        overflow: hidden;
        margin-top: 8px;
        .todo-tabs{
          // height: 100%;
          display: flex;
          align-items: center;
          .item{
            display: flex;
            align-items: center;
            cursor: pointer;
            .text{
              font-size: 16px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 600;
              color: #6D768F;
              line-height: 24px;
              letter-spacing: 1px;
              &:hover{
                color: #409eff;
              }
            }
            .active{
              color: #2A2F3D;
            }
            & + .item{
              margin-left: 24px;
            }
          }
        }
        .todo-main-warp{
          padding-top: 15px;
          height: calc(100% - 24px);
          box-sizing: border-box;
          overflow: hidden;
          .dynamic-box{
            height: 100%;
            display: flex;
            flex-direction: column;
            .pagination {
              height: 32px;
              text-align: right;
            }
            .dynamic-list{
              flex: 1;
              height: 100%;
            }
          }
          .image-box{
            height: 100%;
            box-sizing: border-box;
            .img{
              height: 100%;
              width: 100%;
            }
          }
        }
        
      }
    }
    
  }
</style>